.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App p {
  max-width: 60%;
}

.App a {
  color: white;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  height: 800px;
  max-width: 1024px;
  margin: 0 auto;
  box-sizing: border-box;
}

.head {
  display: block;
  text-align: center;
  color: #6727cf;
  margin: 0 auto;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: smaller;
}

@media only screen and (max-width: 400px) {
  .App p {
    max-width: 90%;
  }
}